<template>
  <div>

    <v-row v-if="isLoading">
      <v-col v-for="(item,i) in 8" :key="i" cols="12" lg="3" md="4">
        <v-card class="rounded-lg" outlined>
          <v-card-text>
            <v-skeleton-loader type="card-avatar"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row v-else>

      <v-col v-if="!agencies.length" cols="12">
        <v-card class="transparent" flat>
          <v-card-text>
            <NoResult/>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-for="(item,index) in agencies" :key="index" lg="3" md="4">
        <v-card class="rounded-lg" outlined>
          <v-card-title>
            <v-spacer/>

            <v-icon v-if="item.is_active" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="red">mdi-cancel</v-icon>

          </v-card-title>
          <v-card-text class="text-center">

            <v-avatar v-if="item.photo" size="80">
              <v-img :src="$baseUrl + item.photo"
                     class="zoom-pointer rounded"
                     @click="$refs.lightbox.open(item.photo)"/>
            </v-avatar>

            <v-avatar v-else
                      :class="$func.randomColor()"
                      class="font-weight-medium avatar-border"
                      size="80">
              <span class="fs-21"> {{ $func.avatarName(item.name) }}</span>
            </v-avatar>


            <h3 class="pt-5 pb-2 font-weight-medium fs-17">{{ item.name }}</h3>

            <p class="mb-0 font-weight-medium">
              {{ item.email }}
            </p>

          </v-card-text>

          <v-divider/>

          <v-card-actions>

            <v-switch v-model="item.is_active"
                      class="pa-0 ma-0"
                      color="secondary"
                      hide-details
                      readonly
                      @click="$emit('change-status',item.id)"/>

            <v-spacer/>
            <v-btn icon>
              <v-icon size="20">mdi-chart-pie</v-icon>
            </v-btn>
            <v-btn icon @click="$emit('show',item)">
              <v-icon size="20">mdi-eye-plus</v-icon>
            </v-btn>
            <v-btn icon @click="$emit('edit',item)">
              <v-icon size="20">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn color="red" icon @click="$emit('delete',[item.id,index])" disabled>
              <v-icon size="20">mdi-trash-can</v-icon>
            </v-btn>

          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>

export default {
    props: ['agencies', 'isLoading']
}
</script>

<style scoped>

</style>