<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>

      <v-card class="shadow rounded-lg">

        <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
          <v-col cols="12" lg="3" order="1" order-lg="0">
            <v-text-field v-model.trim="search"
                          class="rounded-input"
                          clearable
                          dense
                          hide-details
                          outlined
                          placeholder="Recherche..."
                          prepend-inner-icon="mdi-magnify"
                          rounded
                          single-line/>
          </v-col>

          <v-col class="text-end" cols="12" lg="9">

            <v-btn :class="view === 'grid' && 'bg-primary-subtle'"
                   :ripple="false"
                   class="rounded"
                   color="primary"
                   text
                   @click="view = 'grid'">
              <v-icon :left="!$vuetify.breakpoint.mobile">mdi-view-grid-outline</v-icon>
              {{ !$vuetify.breakpoint.mobile ? 'Cartes' : '' }}
            </v-btn>

            <v-btn :class="view === 'table' && 'bg-primary-subtle'"
                   :ripple="false"
                   class="rounded ml-2"
                   color="primary"
                   text
                   @click="view = 'table'">
              <v-icon :left="!$vuetify.breakpoint.mobile">mdi-table-border</v-icon>
              {{ !$vuetify.breakpoint.mobile ? 'Tableau' : '' }}
            </v-btn>

            <v-btn class="mx-2"
                   color="primary"
                   depressed
                   @click="$refs.agencyForm.open(null,'Ajouter un compte agence')">
              <v-icon left>mdi-plus</v-icon>
              Agence
            </v-btn>

            <v-btn color="primary"
                   icon @click="getAgencies">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-divider/>

        <v-card-text>

          <GridView v-if="view === 'grid'"
                    :agencies.sync="searchAgencies"
                    :is-loading="isLoading"
                    @delete="deleteAgency($event)"
                    @edit="$refs.agencyForm.open($event,'Modifier un compte agence')"
                    @refresh="getAgencies"
                    @show="$refs.agencyDetailDialog.open($event)"
                    @show-regionals="$refs.regionalsListDialog.open($event)"
                    @change-status="changeStatus($event)"/>

          <TableView v-if="view === 'table'"
                     :agencies.sync="searchAgencies"
                     :is-loading="isLoading"
                     @delete="deleteAgency($event)"
                     @edit="$refs.agencyForm.open($event,'Modifier un compte agence')"
                     @refresh="getAgencies"
                     @show="$refs.agencyDetailDialog.open($event)"
                     @show-regionals="$refs.regionalsListDialog.open($event)"
                     @change-status="changeStatus($event)"/>

        </v-card-text>

      </v-card>

      <Lightbox ref="lightbox"/>
      <AgencyForm ref="agencyForm" @refresh="getAgencies"/>
      <ConfirmDialog ref="confirmDialog"/>
      <AgencyDetailDialog ref="agencyDetailDialog"/>
      <RegionalsListDialog ref="regionalsListDialog"/>

    </v-container>
  </div>
</template>

<script>
import GridView from "@/views/agencies/components/GridView.vue";
import TableView from "@/views/agencies/components/TableView.vue";
import AgencyForm from "@/views/agencies/components/AgencyForm.vue";
import AgencyDetailDialog from "@/views/agencies/components/AgencyDetailDialog.vue";
import RegionalsListDialog from "@/views/agencies/components/RegionalsListDialog.vue";
import {HTTP} from "@/http-common";

export default {
    components: {AgencyForm, TableView, GridView, AgencyDetailDialog, RegionalsListDialog},
    data() {
        return {
            view: 'grid',
            overlay: false,
            isLoading: false,
            agencies: [],

            search: '',
            filter: {}
        }
    },

    methods: {
        getAgencies() {
            this.isLoading = true
            HTTP.get('/agencies', {
                params: {
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.agencies = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteAgency(event) {
            if (await this.$refs.confirmDialog.open('', '', {
                icon: '',
                agreeText: '',
                color: 'red',
            })) {
                /**
                 * array
                 */
                let index = event[1]
                let id = event[0]

                this.overlay = true
                HTTP.delete('agencies/' + id).then(() => {
                    this.agencies.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cette agence a été supprimée avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        async changeStatus(userId) {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir changer le statut de cette agence ?', {
                icon: 'mdi-backup-restore',
                agreeText: 'Changer',
                color: 'orange',
            })) {
                this.overlay = true
                HTTP.post('/agencies/' + userId + '/change-status').then(() => {
                    this.overlay = false
                    this.getAgencies()
                    this.$successMessage = 'Cette agence a été modifiée avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        onPageChange() {
            this.getAgencies();
        },
    },
    created() {
        this.getAgencies()
    },
    watch: {
        view() {
            this.getAgencies();
        },
    },
    computed: {
        searchAgencies() {
            return this.agencies.filter(item => {
                if (this.search) {
                    return item.name.toLowerCase().includes(this.search.toLowerCase())
                        || item.email.toLowerCase().includes(this.search.toLowerCase())
                        || item.phone.toLowerCase().includes(this.search.toLowerCase())
                } else {
                    return item
                }
            });
        }
    },
}
</script>

<style scoped>
.v-tab {
    min-width: 30px;
}
</style>