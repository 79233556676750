<template>
  <div>

    <v-skeleton-loader v-if="isLoading" type="table"/>

    <v-data-table v-if="agencies.length"
                  :headers="headers"
                  :items="agencies"
                  :items-per-page="10"
                  class="table-border rounded-lg"
    >
      <template v-slot:item.actions="{ item,index }">
        <div class="d-flex">
          <v-btn color="red" icon @click="$emit('delete',[item.id,index])" disabled>
            <v-icon size="20">mdi-trash-can</v-icon>
          </v-btn>
          <v-btn icon @click="$emit('edit',item)">
            <v-icon size="20">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn icon @click="$emit('show',item)">
            <v-icon size="20">mdi-eye-plus</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon size="20">mdi-chart-pie</v-icon>
          </v-btn>
        </div>
      </template>


      <template v-slot:item.name="{ item }">
        <div class="d-flex text-no-wrap align-center text-truncate">

          <v-avatar v-if="item.photo" size="38">
            <v-img :src="$baseUrl + item.photo"
                   class="zoom-pointer"
                   @click="$refs.lightbox.open(item.photo)"></v-img>
          </v-avatar>

          <v-avatar v-else
                    :class="$func.randomColor()"
                    class="font-weight-medium"
                    size="38">
            {{ $func.avatarName(item.name) }}
          </v-avatar>

          <span class="ml-2"> {{ item.name }}</span>
        </div>
      </template>


      <template v-slot:item.is_active="{ item }">
        <v-switch v-model="item.is_active"
                  class="pa-0 ma-0"
                  color="secondary"
                  hide-details
                  readonly
                  @click="$emit('change-status',item.id)"/>
      </template>

      <template v-slot:item.status="{ item }">

        <v-chip v-if="item.is_active"
                class="font-weight-medium bg-success-subtle"
                small
                text-color="success">Actif
        </v-chip>

        <v-chip v-else
                class="font-weight-medium bg-red-subtle"
                small
                text-color="red">Inactif
        </v-chip>

      </template>

      <template v-slot:item.email="{  item }">
        <a :href="'mailto:'+item.email"
           :style="{maxWidth: '150px'}"
           class="d-block text-truncate orange--text text-decoration-none">
          {{ item.email }}
        </a>
      </template>

      <template v-slot:item.regionals="{  item }">
        <v-chip :disabled="!item.regionals.length"
                class="font-weight-medium bg-primary-subtle"
                small
                text-color="primary"
                @click="$emit('show-regionals',item.regionals)">
          {{ item.regionals.length }} Régionaux
          <v-icon color="primary" right small>mdi-arrow-right</v-icon>
        </v-chip>
      </template>

    </v-data-table>

    <NoResult v-else/>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
export default {
    props: ['agencies', 'isLoading'],
    computed: {
        headers() {
            let _headers = [
                {
                    text: 'Statut',
                    value: 'is_active',
                    show: true
                },
                {
                    text: 'Nom',
                    value: 'name',
                    show: true
                },
                {
                    text: 'N°Téléphone',
                    value: 'phone',
                    show: true
                },
                {
                    text: 'Email',
                    value: 'email',
                    show: true
                },
                {
                    text: 'Régionaux',
                    value: 'regionals',
                    align: 'center',
                    show: true
                },
                {
                    text: 'Compte',
                    value: 'status',
                    show: true
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    show: true
                },
            ]

            return _headers.filter(x => x.show)
        },
    },

}
</script>

<style scoped>

</style>