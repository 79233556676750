<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card>

        <v-card-title>
          Informations détaillées
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">


          <v-simple-table class="rounded-lg table-border">
            <template v-slot:default>
              <tbody>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Compte</td>
                <td>
                  <v-chip v-if="agency.is_active"
                          class="font-weight-medium bg-success-subtle"
                          small
                          text-color="success">Actif
                  </v-chip>

                  <v-chip v-else
                          class="font-weight-medium bg-red-subtle"
                          small
                          text-color="red">Inactif
                  </v-chip>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Photo</td>
                <td>
                  <v-avatar v-if="agency.photo" size="38">
                    <v-img :src="$baseUrl + agency.photo"
                           class="zoom-pointer"
                           @click="$refs.lightbox.open(agency.photo)"></v-img>
                  </v-avatar>

                  <v-avatar v-else
                            :class="$func.randomColor()"
                            class="font-weight-medium"
                            size="38">
                    {{ $func.avatarName(agency.name) }}
                  </v-avatar>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Nom</td>
                <td>
                  {{ agency.name }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Email</td>
                <td>
                  <a :href="'mailto:'+agency.email"
                     class="orange--text">
                    {{ agency.email }}
                  </a>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">N°Téléphone</td>
                <td>
                  {{ agency.phone }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Wilaya</td>
                <td>
                  {{ agency.city ? agency.city.name : '-' }}
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Région</td>
                <td>
                  {{ agency.region ? agency.region.name : '-' }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Commune</td>
                <td>
                  {{ agency.municipality ? agency.municipality.name : '-' }}
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Adresse</td>
                <td>
                  {{ agency.address }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Date de création</td>
                <td>
                  {{ agency.created_at }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            agency: {}
        }
    },
    methods: {
        open(agency) {
            this.agency = agency
            this.dialog = true
        },
    },
}
</script>

<style scoped>

</style>